import { graphql } from "gatsby"
import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"

export default function Index() {
  const { languages, originalPath } = useI18next() // Get language info
  const { t, i18n } = useTranslation() // Get translation info

  const siteUrl = "https://arcanode.io"

  return (
    <>
      <GatsbySeo
        title={t("home.seo.title", "Arcanode")}
        description={t(
          "home.seo.description",
          "Arcanode provides expertise and innovation for retail, wholesalers, and IT companies; it’s the secure and high-performing solution that empowers businesses without compromising on cost and quality."
        )}
        canonical={`${siteUrl}/${i18n.language}${originalPath}`}
        openGraph={{
          url: `${siteUrl}/${i18n.language}${originalPath}`,
          title: t("home.seo.title", "Arcanode"),
          description: t(
            "home.seo.description",
            "Discover Arcanode’s innovative solutions for retail, wholesalers, and IT companies. We provide secure, high-performance Web3 and IT solutions to empower your business."
          ),
          images: [
            {
              url: `${siteUrl}/icons/icon-512x512.png`,
              width: 512,
              height: 512,
              alt: "Arcanode",
            },
          ],
          site_name: "Arcanode",
        }}
        twitter={{
          handle: "@arcanode",
          site: "@arcanode",
          cardType: "summary_large_image",
        }}
        languageAlternates={languages.map(lang => ({
          hrefLang: lang,
          href: `${siteUrl}/${lang}${originalPath}`,
        }))}
      />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "Arcanode",
          url: "https://arcanode.io",
          description:
            "Arcanode provides expertise and innovation for retail, wholesalers, and IT companies; it’s the secure and high-performing solution that empowers businesses without compromising on cost and quality.",
        })}
      </script>

      {/* Hidden content specifically for SEO purposes */}
      <div
        style={{
          position: "absolute",
          width: "1px",
          height: "1px",
          overflow: "hidden",
          clip: "rect(1px, 1px, 1px, 1px)",
        }}
      >
        <h1>{t("home.seo.title")}</h1>
        <p>
          {t(
            "home.seo.description",
            "Welcome to Arcanode, where we offer high-tech, secure solutions for retail, wholesale, and IT businesses. Our expertise spans from Web3-based platforms to immersive VR and WebGL environments that empower your business while maintaining cost efficiency"
          )}
        </p>
      </div>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
